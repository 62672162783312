import React, { FC } from "react";

import IAction, { ACTION } from "../../types/Action.types";
import ICard from "../../types/Card.types";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { Card } from "../commons/Card";
import { uniq } from "lodash";
import IPlayer from "../../types/Player.types";
import { HandPokerSolver } from "../pages/AddHand";

interface IActionSection {
    title: string,
    actions: IAction[],
    bb: number,
    pot: number,
    cards: ICard[],
    withBorder?: boolean,
    heroNick: string,
    players: IPlayer[]
}

export const ActionSection: FC<IActionSection> = ({
    title,
    actions,
    bb,
    pot,
    cards,
    withBorder,
    heroNick,
    players
}) => {

    const playersSize = uniq((actions.map(action => action.nick))).length;

    const allCards: string[] = [];
    title !== 'PREFLOP' && cards.forEach(boardCard => allCards.push(boardCard.figure + boardCard.color));

    return (<ActionContainer marginX={16} withBorder={withBorder}>
        <Row align={'center'}>
            <Column>
                <h3>{ title }</h3>
                { title !== 'BLIND' && (playersSize > 0 && (<div>{ playersSize } Players, { Math.round(pot * 10 / bb) / 10} bb</div>) )}
            </Column>
            <Row gap={4}>
            {
                cards.map((card: ICard) => (<Card 
                    background={colors.cards[card.color]} 
                    figure={card.figure} />))
            }
            </Row>
        </Row>
        { playersSize > 0 && (<Description>
        {
            actions.map(action => {
                const heroCards = players.find(player => player?.nick === action.nick)?.cards;
                const actualAllCards: string[] = allCards.concat([]);
                heroCards?.forEach(card => actualAllCards.push(card.figure + card.color));
                const handSolver = actualAllCards.length > 0 && HandPokerSolver.solve(actualAllCards);

                return (<Row gap={8} flex={1} isHero={action.nick === heroNick}>
                    <div>{ action.nick }</div>
                    <ActionDiv color={!!action.betType ? colors.betTypes[action.betType] : ''}>{ action.type.replace('{0}', ((action.amountInBB || 0)).toString()) }</ActionDiv>
                    <div>{ title !== 'BLIND' && '(' + Math.round(action.time / 1000) + 's)' }</div>
                    <Row gap={4} width={54}>
                    {
                        heroCards?.map((card: ICard) => (<Card 
                            width={25}
                            height={35}
                            background={colors.cards[card.color]} 
                            figure={card.figure}
                            fontSize={24} />))
                    }
                    </Row>
                    <div>{ handSolver?.name }</div>
                </Row>)
            })
        }
        </Description>) }
    </ActionContainer>);
};

const ActionContainer = styled.div<{marginX?: number, withBorder?: boolean}>`
    background: ${colors.actionBg};
    padding-top: ${p => p.marginX && p.marginX}px;
    padding-bottom: ${p => p.marginX && p.marginX}px;
    font-family: sans-serif;
    font-weight: 500;

    border-bottom: ${p => p.withBorder && '2px solid ' + colors.border};
`;

const Row = styled.div<{gap?: number, align?: string, flex?: number, isHero?: boolean, width?: number}>`
    display: flex;
    gap: ${p => p.gap ? p.gap : 16}px;
    align-items: ${p => p.align && p.align };
    text-align: left;
    flex: unset !important;
    align-items: center;

    h3 {
        margin: 0;
        font-family: sans-serif;
    }

    div {
        flex: ${p => p.flex};
    }

    > div:nth-child(5) {
        flex: ${p => p.flex && '2'};
    }

    color: ${p => p.isHero && colors.highlight};
    font-weight: ${p => p.isHero && 600};

    width: ${p => p.width}px;
`;

const Column = styled.div<{gap?: number, align?: string}>`
    display: flex;
    align-items: ${p => p.align && p.align };
    flex-direction: column;
    gap: ${p => p.gap ? p.gap : 8}px;
    min-width: 200px;
`;

const Description = styled.div`
    margin-top: 16px;
    color: ${colors.soft};
    display: flex;
    flex-direction: column;
    gap: 4px;

    > div > span {
        color: ${colors.highlight};
        font-weight: 600;
    }

    small {
        color: ${colors.white};
    }
`;

const ActionDiv = styled.div<{color?: string}>`
    color: ${p => p.color}
`;