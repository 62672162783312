import { cloneDeep } from "lodash";
import { ACTION } from "../types/Action.types";
import IHand from "../types/Hand.types";
import IPlayer from "../types/Player.types";

const mapAction = (action: ACTION, value: number, prevValue?: number) => {
    if (action.valueOf() === ACTION.ANTE.valueOf()) {
        return `puts ante ${value}`;
    } else if (action.valueOf() === ACTION.CALL.valueOf()) {
        return `calls ${value}`;
    } else if (action.valueOf() === ACTION.FOLD.valueOf()) {
        return `folds`;
    } else if (action.valueOf() === ACTION.CHECK.valueOf()) {
        return `checks`;
    } else if (action.valueOf() === ACTION.BET.valueOf()) {
        return `bets ${value}`;
    } else if (action.valueOf() === ACTION.ALL_IN.valueOf()) {
        return `bets ${value} and is all-in`;
    } else if (action.valueOf() === ACTION.RAISE.valueOf()) {
        return `raises ${prevValue} to ${value}`;
    }
}

export const uploadPSHandHistory = (hand: IHand) => {
    alert(`Hand ${hand.title} history file upload started`);
    const dateObject = new Date(hand.date);
    const buttonIdx = hand.players.length === 2 ? 1 : (hand.players.length - 2);

    let text = `PokerStars Hand #${hand.title.replaceAll('-', '')}:`;
    text +=  `Hold'em No Limit (${hand.sb}/${hand.bb})`;
    text += ` - ${dateObject.toLocaleString("en-CA", {
        timeZone: "Asia/Seoul",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
    }).replace(/-/g, "/").replace(',', '')} KST `
    text += `[${dateObject.toLocaleString("en-CA", {
        timeZone: "UTC",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
    }).replace(/-/g, "/").replace(',', '')} ET]\n`;

    text += `Table 'Example' ${hand.players.length}-max Seat #${buttonIdx} is the button\n`;

    hand.players.forEach((player: IPlayer, idx: number) => {
        text += `Seat ${idx + 1}: ${player.nick} (${player.stack} in chips)\n`;
    });

    hand.players.forEach((player: IPlayer, idx: number) => {
        text += `Seat ${idx + 1}: ${player.nick} posts ante ${hand.bb}\n`;
    });

    const flop = cloneDeep(hand.board).splice(0, 3);
    const turn = cloneDeep(hand.board).splice(0, 4);

    if (hand.preflop.length > 0) {
        text += `*** HOLE CARDS ***\n`;
        hand.preflop.forEach(action => {
            console.timeLog(action.type);
            text += `${action.nick}: ${mapAction(action.type, (action.amount || 0), 0)}\n`; // TODO: pass proper prevValue (last bet size)
        })
    }

    if (hand.flop.length > 0 || hand.board.length > 0) {
        text += `*** FLOP *** [${flop.map(card => card.figure + card.color).reduce((s1, s2) => s1 + ', ' + s2)}]\n`;
        if (hand.flop.length > 0) {
            hand.flop.forEach(action => {
                console.timeLog(action.type);
                text += `${action.nick}: ${mapAction(action.type, (action.amount || 0), 0)}\n`; // TODO: pass proper prevValue (last bet size)
            });
        }
    }

    if (hand.turn.length > 0 || hand.board.length > 3) {
        text += `*** TURN *** [${flop.map(card => card.figure + card.color).reduce((s1, s2) => s1 + ', ' + s2)}] [${cloneDeep(hand.board).splice(3, 3).map(card => card.figure + card.color)}]\n`;
        if (hand.turn.length > 0) {
            hand.turn.forEach(action => {
                console.timeLog(action.type);
                text += `${action.nick}: ${mapAction(action.type, (action.amount || 0), 0)}\n`; // TODO: pass proper prevValue (last bet size)
            });
        }
    }

    if (hand.river.length > 0 || hand.board.length > 4) {
        text += `*** RIVER *** ${turn.map(card => card.figure + card.color).reduce((s1, s2) => s1 + ', ' + s2)}] [${cloneDeep(hand.board).splice(4, 4).map(card => card.figure + card.color)}]\n`;
        if (hand.river.length > 0) {
            hand.river.forEach(action => {
                console.timeLog(action.type);
                text += `${action.nick}: ${mapAction(action.type, (action.amount || 0), 0)}\n`; // TODO: pass proper prevValue (last bet size)
            });
        }
    }

    text += `*** SUMMARY ***\n`;
    text += `Total pot ${hand.pot[hand.pot.length - 1]} | Rake ${Math.round(5.3 * hand.pot[hand.pot.length - 1] / 100)}\n`;
    if (hand.board.length > 0) {
        text += `Board [${cloneDeep(hand.board).map(card => card.figure + card.color).reduce((s1, s2) => s1 + ' ' + s2)}]\n`;
    }
    hand.players.forEach((player: IPlayer, idx: number) => {
        text += `Seat ${idx + 1}: ${player.nick} \n`; // TODO: add expected info in result
    })

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', hand.title);

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}