import React, { 
    FC, 
    useEffect,
    useState,
} from "react";

import IHand from "../../types/Hand.types";
import { styled } from "styled-components";
import { Card } from "../commons/Card";
import { colors } from "../../styles/colors";
import { Actions } from "./Actions";
import { cloneDeep } from "lodash";
import ITag from "../../types/Tag.types";
import { Tag } from "../commons/Tag";
import icons, { Icon } from "../commons/Icon";
import { uploadPSHandHistory } from "../../utils/HandExporter.utils";

interface IPlayerHand {
    hand: IHand;
    openDetails?: (handUuid: string) => void;
    no: number;
    heroNick: string;
    onSelectHand: (hand: IHand) => void;
    onGlobalClick?: (hand: IHand) => void;
    selectedHandUuid: string;
    hideFinalBalance?: boolean;
    polishTime?: boolean;
    showIsReviewed?: boolean;
    showHeroHand?: boolean;
};

const colorIndexes = Array.from(Array(9).keys());

const randOrder = (a: number, b: number) => {  
    return 0.5 - Math.random();
  };

export const PlayerHand: FC<IPlayerHand> = ({
    hand,
    openDetails,
    no,
    heroNick,
    onSelectHand,
    selectedHandUuid,
    hideFinalBalance,
    polishTime,
    onGlobalClick,
    showIsReviewed,
    showHeroHand,
}) => {

    const hero = hand.players.filter(player => player.nick === heroNick)[0];

    const flop = cloneDeep(hand.board.sort((h1, h2) => (h1?.boardId || 0) - (h2?.boardId || 0))).splice(0, 3);
    const turn = cloneDeep(hand.board.sort((h1, h2) => (h1?.boardId || 0) - (h2?.boardId || 0))).splice(3, 1);
    const river = cloneDeep(hand.board.sort((h1, h2) => (h1?.boardId || 0) - (h2?.boardId || 0))).splice(4, 1);
    const [colorIndexesForHand, setColorIndexesForHand] = useState<number[]>(colorIndexes);

    useEffect(() => {
        setColorIndexesForHand(colorIndexes.sort(randOrder));
    }, []);

    const dateObject = new Date(hand.date + "+0900");

    return (<Row>
        <GlobalRow isSelected={selectedHandUuid === hand?.uuid} onClick={() => !!openDetails ? openDetails(hand?.uuid || '') : (!!onGlobalClick && onGlobalClick(hand))} cursor={'pointer'}>
            <Row gap={16} first={true} borderRight={true}>
                <BalanceRow width={50}>{ (hand?.bb * 10) / 10000 }k</BalanceRow>
                <MainRow gap={32}>
                    { (hand.preflop?.length > 0 || showHeroHand) && (<Row gap={8}>
                            <Cards margin={16}>
                            { hand.players.find(player => player.nick.trim() == heroNick.trim())?.cards?.map(card => <Card background={colors.cards[card.color]} figure={card.figure} />)}
                            </Cards>
                            <Actions actions={hand.preflop} heroNick={hero?.nick} players={hand.players} colorIndexes={colorIndexesForHand} />
                        </Row>)
                    }
                    { (hand.flop?.length > 0 || hand.board.length > 0) && (<Row gap={8}>
                            <Cards>
                            { flop.map(card => <Card background={colors.cards[card.color]} figure={card.figure} />)}
                            </Cards>
                            <Pot>{ Math.round(hand.pot[1] / hand.bb) }</Pot>
                            <Actions actions={hand.flop} heroNick={hero?.nick} players={hand.players} colorIndexes={colorIndexesForHand} />
                        </Row>)
                    }
                    { (hand.turn?.length > 0 || hand.board.length > 3) && (<Row gap={8}>
                            <Cards>
                            { turn.map(card => <Card background={colors.cards[card.color]} figure={card.figure} />)}
                            </Cards>
                            <Pot>{ Math.round(hand.pot[2] / hand.bb) }</Pot>
                            <Actions actions={hand.turn} heroNick={hero?.nick} players={hand.players} colorIndexes={colorIndexesForHand} />
                        </Row>)
                    }
                    { (hand.river?.length > 0 || hand.board.length > 4) && (<Row gap={8}>
                            <Cards>
                            { river.map(card => <Card background={colors.cards[card.color]} figure={card.figure} />)}
                            </Cards>
                            <Pot>{ Math.round(hand.pot[3] / hand.bb) }</Pot>
                            <Actions actions={hand.river} heroNick={hero?.nick} players={hand.players} colorIndexes={colorIndexesForHand} />
                        </Row>)
                    }
                </MainRow>
            </Row>
                { !hideFinalBalance ? (<BalanceRow color={(hero?.balance || 0) > 0 ? colors.success : colors.failureStrong}>
                    {
                        Math.round(((((hero?.balance || 0) * 100 / hand.bb))) / 100)
                    } bb
                    </BalanceRow>) :
                    (<TimeRow> { (dateObject.toLocaleString("en-GB", {timeZone: polishTime ? "Europe/Warsaw" : "Asia/Seoul"})) }</TimeRow>)
                }
                { showIsReviewed ? (<ReviewDiv>
                    { hand?.reviewed && (<Icon icon={icons.TickIcon} onClick={() => {}} />) }
                </ReviewDiv>) : <TagColumn gap={4}>
                {
                    hero?.tags?.filter(innerTag => !!innerTag)?.map((tag: ITag) => (
                        <Tag 
                            uuid={tag.uuid} 
                            name={tag.name} 
                            color={tag.color} 
                            bgColor={tag.bgColor}/>)
                    )
                }
                </TagColumn>
            }
        </GlobalRow>
        <IconWrapper>
            <Icon fill={colors.whiteStrong} icon={icons.CopyIcon} onClick={() => uploadPSHandHistory(hand)} />
        </IconWrapper>
    </Row>);
};

const Row = styled.div<{gap?: number, cursor?: string, first?: boolean, isSelected?: boolean, borderRight?: boolean}>`
    background-color: ${p => p.isSelected && colors.highlightedPlayer2};
    display: flex;
    gap: ${p => p.gap}px;
    font-family: sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    align-items: center;
    cursor: ${p => p.cursor};
    min-height: 50px;
    ${p => p.borderRight && `border-right: 2px solid ${colors.soft};`}
`;

const GlobalRow = styled(Row)`
    gap: 0;
    
`;

const BalanceRow = styled(Row)<{color?: string, width?: number}>`
    padding: 0 8px;
    font-size: 18px;
    color: ${p => p.color};
    width: ${p => p.width || 100}px;
    justify-content: flex-end;
`;

const TimeRow = styled(Row)`
    padding: 0 8px;
    border-left: 2px double ${colors.soft};
    font-size: 18px;
    color: ${p => p.color};
    width: 200px;
    justify-content: flex-end;
`;

const MainRow = styled(Row)`
    padding: 0 8px;
    padding-top: 8px;
    overflow: scroll;
    border-left: 2px solid ${colors.soft};
    width: 1300px;
    @media (max-width: 2000px) {
        width: calc(100vw - 620px);
    }
    overflow: scroll;
`;

const Cards = styled(Row)<{margin?: number}>`
    margin-left: ${p => p.margin}px;
    margin-right: ${p => p.margin}px;
    gap: 4px;
`;

const Pot = styled.div`
    font-weight: 600;
    font-size: 21px;
    line-height: 40px;
    color: ${colors.whiteStrong};
    margin-right: 32px;
`;

export const ButtonsRow = styled(Row)`
    width: fit-content;
    padding-right: 16px;
    padding-top: 24px;
    padding-bottom: 24px;
`;

const NoRow = styled(Row)`
    padding-left: 16px;
    color: ${colors.whiteStrong};
    font-size: 18px;
    width: 140px;
`;

const TagColumn = styled(Row)`
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    align-items: left;
    width: 150px;
`;

const ReviewDiv = styled.div`
    margin-left: 100px;
    min-width: 30px;

    path {
        fill: ${colors.success};
    }
`;

const IconWrapper = styled.div`
    padding: 8px;
`;