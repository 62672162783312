import ICard from "./Card.types";
import ITag from "./Tag.types";

export const POSITIONS: string[] = [
    'UTG3',
    'UTG2',
    'UTG1',
    'EP',
    'MP',
    'CO',
    'BTN',
    'SB',
    'BB'
];

export default interface IPlayer {
    id?: number;
    uuid?: string,
    nick: string,
    stack: number,
    isHero: boolean,
    cards: ICard[],
    result?: string,
    balance?: number;
    positionId?: number;
    tags: ITag[];
};