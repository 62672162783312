export enum ACTION {
    FOLD = 'Folds',
    CHECK = 'Checks',
    CALL = 'Calls {0} bb',
    BET = 'Bets {0} bb',
    RAISE = 'Raises to {0} bb',
    ALL_IN = 'Raises to {0} bb and it\'s All-In',
    BB = 'Calls Big Blind ({0} bb)',
    SB = 'Calls Small Blind ({0} bb)',
    ANTE = 'Puts Ante {0} bb'
};

export enum TYPE {
    QUARTER = 'q',
    HALF = 'h',
    POT = 'p',
    MANUAL = 'm'
}

export default interface IAction {
    uuid?: string;
    actionId?: number;
    playerId?: number;
    nick: string;
    type: ACTION;
    amount?: number;
    amountInBB?: number;
    time: number;
    betType?: TYPE;
};