import React, { FC } from "react";

import IPlayer, { POSITIONS } from "../../types/Player.types";
import { PlayerInfo } from "./PlayerInfo";
import { cloneDeep } from "lodash";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import ITag from "../../types/Tag.types";
import IPlayerGlobal from "../../types/IPlayerGlobal";

interface IPlayersList {
    players: IPlayer[];
    bb: number;
    heroNick: string;
    allTags: ITag[];
    allPlayers: IPlayerGlobal[];
}

export const PlayersList: FC<IPlayersList> = ({
    players,
    bb,
    heroNick,
    allTags,
    allPlayers,
}) => {

    const positions = cloneDeep(POSITIONS).splice(9 - players.length);
    
    return (<Row>
        <Column>
        {
            players.sort((p1, p2) => (p1?.positionId  || 0) - (p2?.positionId || 0)).map(player => <PlayerInfo 
                allTags={allTags}
                player={player}
                positions={positions}
                bb={bb}
                cards={player?.cards}
                heroNick={heroNick}
                playerUuid={allPlayers.filter(playerGlobal => playerGlobal.playerNick === player?.nick)?.[0]?.uuid}
            />)
        }
        </Column>
    </Row>);
};

const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: ${colors.soft};
    font-weigth: 600;
    font-family: sans-serif;
`;

const Row = styled.div`
    justify-content: space-between;
    align-items: center;
    display: flex;
    border-bottom: 2px solid ${colors.border};
    padding-bottom: 24px;

    > button:last-child {
        margin: 0 auto;
    }
`;